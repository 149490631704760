<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->
    <!-- Start Column Area  -->
    <div  class="rn-column-area rn-section-gap bg_color--5 mt--150">
          <v-row>
              <v-col lg="6"
              md="6"
              sm="12"
              cols="12">
                <v-row align="center" justify="center" >
                  <v-col align="center" justify="center" >
                    <v-img data-aos="fade-left" data-aos-duration="1200" width="650" src="@/assets/images/blog/myaccount.png" alt="Creative Agency" />
                  </v-col>
                </v-row>
                <v-row align="center" justify="center" >
                  <p>Κατέβασε το app</p><br>
                </v-row>
                <v-row align="center" justify="center" >
                  <v-col align="right">
                    <a href="https://apps.apple.com/us/app/id1489983713"  target="blank" class="app-btn1"><v-img width="100" src="@/assets/images/blog/appstore-tra-grey.png" alt=""/></a>
                  </v-col>
                  
                  <v-col>
                    <a href="https://play.google.com/store/apps/details?id=com.rcflavorproba"  target="blank" class="app-btn2"><v-img width="100" src="@/assets/images/blog/googleplay-tra-grey.png" alt="" /></a>
                  </v-col>
                </v-row>
            </v-col>
              
               <v-col lg="4"
                     md="4"
                     sm="12"
                     cols="12">

                      <div class="single-service text-white mt--50 container">
                  <v-form ref="form"
                          lazy-validation>    
                          <v-row align="center" justify="center" v-if="user">   
                              <v-col>
                                <v-img style="float: right;" width="30" src="@/assets/images/logo/logo_map.png" ></v-img>
                              </v-col>    
                              <v-col>
                                <p style="float: left;"> {{ user.total_points }} Πόντοι</p>       
                              </v-col>                                      
                          </v-row> 
                       <h2 class="mt--40">Ρυθμίσεις προφίλ</h2>
                        <br>
                      <v-text-field
                          :label="user.mobile"
                          v-model="mobile"
                          persistent-hint
                          disabled  
                          outlined
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="user.full_name"
                          label="Ονοματεπώνυμο"
                          :rules="[rules.required]"
                          outlined
                          shaped
                        ></v-text-field>                                                                                                                                    
                        <v-text-field
                          v-model="user.email"
                          :rules="[rules.required, rules.emailRules]"
                          outlined
                          shaped
                          label="Email"
                        ></v-text-field>
                              <v-text-field
                                v-model="user.birth_date"
                                label="Ημερομηνία Γέννησης"
                                prepend-icon="mdi-calendar"
                                readonly
                              ></v-text-field>
                              <v-radio-group
                              v-model="user.gender"
                                row
                              >
                                <v-radio
                                  label="Άντρας"
                                  value="male"
                                  name="gender"
                                ></v-radio>
                                <v-radio
                                  label="Γυναίκα"
                                  value="female"
                                  name="gender"
                                ></v-radio>
                                <v-radio
                                  label="Άλλο"
                                  value="other"
                                  name="gender"

                                ></v-radio>
                              </v-radio-group>
                              <div class="" v-for="user_notification in user.user_notifications" :key="user_notification.type">
                                <v-checkbox
                                  v-model="user_notification.active"
                                  :label="`Αποδοχή ειδοποιήσεων ${user_notification.type.toString()}`"
                                ></v-checkbox>
                                              
                              </div>  
                        <v-btn
                        color="primary"
                          dark
                          class="mr-4"
                          @click="updateProfile"
                        >
                          Αποθήκευση
                        </v-btn>
                      </v-form>                  
                  </div>
                  <ChangePassword/>
              </v-col>                             
          </v-row>
          
    </div>
    <!-- End Column Area  -->

    <Footer />
  </div>
</template>
<script>
  import Header from "@/components/header/Header";
  import Footer from "@/components/footer/FooterTwo";
  import ChangePassword from "@/views/ChangePassword";
  import axios from 'axios';
  import { mapMutations, mapActions, mapGetters } from "vuex";
  import { validationMixin } from 'vuelidate'
  import { required, maxLength, email } from 'vuelidate/lib/validators'


  export default {
    components: {
      Header,
      Footer,
      ChangePassword,
    },

    data: () => ({
      results: [],
      full_name: '',
      name: '',
      mobile: '',
      gender: '',
      email: '',
      checkbox: false,
      sms: false,
      mail: false,
      push: false,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      rules: {
        required: value => !!value || 'Το πεδίο είναι υποχρεωτικό.',
        emailRules: v => (!v || /.+@.+\..+/.test(v)) || 'Το email δεν είναι έγγυρο.',
      },
    }),
    async  created () {
       if(!this.user){
        this.$loading(true);
        await this.getUser();
        this.$loading(false);
        if(!this.user){
         this.$router.push('/')
         return
        }
      }
    },
    computed: {
      ...mapGetters (["user"]),
    },
    methods: {
      ...mapActions(["getUser"]),
   
      async updateProfile(e){
        try{
          if(this.$refs.form.validate()){
               e.preventDefault();
              this.errors = '';
              this.loading = true;
              const response = await axios.post('external/me', 
              {
                  full_name: this.user.full_name,
                  gender: this.user.gender,
                  email: this.user.email,
                  data:   
                  [
                    {
                      user_notification_setting_id: this.user.user_notifications[0].user_notification_setting_id,
                      active: this.user.user_notifications[0].active
                    },
                    {
                      user_notification_setting_id: this.user.user_notifications[1].user_notification_setting_id,
                      active: this.user.user_notifications[1].active
                    },
                    {
                      user_notification_setting_id: this.user.user_notifications[2].user_notification_setting_id,
                      active: this.user.user_notifications[2].active
                    }
                  ]
              }).then(response => {
                  this.$swal({
                          title: "Συγχαρητήρια",
                          text: "Το προφίλ σας ενημερώθηκε επιτυχώς.",
                          icon: "success", //built in icons: success, warning, error, info
                          timer: 3000, //timeOut for auto-close
                            buttons: {
                              confirm: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "",
                                closeModal: true
                              },
                              cancel: {
                                text: "Cancel",
                                value: false,
                                visible: true,
                                className: "",
                                closeModal: true,
                              }
                            }
                        });
              }).catch(error => {
                  console.log(error.response.data)

                  this.loading = false;
                  if (error.response.data.code == 401) {
                      this.errors = "Παρακαλώ ελέγξτε τα στοιχεία σας.";
                  } 
              }); 
            
          }else{
            return false;
          }            
        }catch(e){
            this.error = e.message;
            return e;
        } 
      }
    },
  }
</script>